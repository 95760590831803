import { getMetricPrecision, getMetricValueByKey } from 'utils/actions'
import {
    AVS_KEY,
    BITCOIN_AND_OTHERS_KEY,
    LIQUID_STAKING_KEY,
    SMART_CONTRACT_KEY,
    STABLECOIN_KEY,
} from 'utils/constants'
import { formatOutputNumber, isInvalidNumber } from 'utils/formatter'
import {
    GENERAL_COLUMN,
    MAIN_ASSET_METRIC_GROUPS,
    MOMENTUM_COLUMN,
    REWARD_COLUMN,
    RISK_COLUMN,
    TOOLTIPS,
    getMoreAssetMetricGroups,
} from '../constants'

export const getAllPerformanceChartSeriesConfig = (symbol = '', tags = []) => {
    if (tags?.find(t => t.tagKey === SMART_CONTRACT_KEY)) {
        return [
            {
                key: 'staked_tokens',
                name: `Staked ${symbol}`,
                postfix: '',
                color: 'var(--c-cyan)',
            },
            {
                key: 'fee_revenue',
                name: 'Annualized Fees',
                prefix: '$',
                color: 'var(--c-red)',
            },
            {
                key: 'reward_rate',
                name: 'Reward Rate',
                postfix: '%',
                color: 'var(--c-blue)',
            },
            {
                key: 'daily_trading_volume',
                name: 'Daily Volume',
                prefix: '$',
                color: 'var(--c-orange)',
            },
        ]
    }

    if (tags?.find(t => t.tagKey === LIQUID_STAKING_KEY)) {
        return [
            {
                key: 'price',
                name: 'Price',
                prefix: '$',
                color: 'var(--c-magenta)',
            },
            {
                key: 'staked_tokens',
                name: `Staked ${symbol}`,
                postfix: '',
                color: 'var(--c-cyan)',
            },
            {
                key: 'reward_rate',
                name: 'Reward Rate',
                postfix: '%',
                color: 'var(--c-blue)',
            },
            {
                key: 'exchange_ratio',
                name: 'Exchange Ratio',
                postfix: '',
                color: 'var(--c-orange)',
            },
            {
                key: 'market_ratio',
                name: 'Market Ratio',
                postfix: '',
                color: 'var(--c-green)',
            },
            {
                key: 'peg_deviation',
                name: 'Peg Accuracy',
                postfix: '%',
                color: 'var(--c-yellow)',
            },
        ]
    }

    if (tags?.find(t => t.tagKey === AVS_KEY)) {
        return [
            {
                key: 'restaking_marketcap',
                name: 'Economic Security',
                prefix: '$',
                color: 'var(--c-magenta)',
            },
            {
                key: 'restaked_tokens',
                name: `Restaked ETH`,
                color: 'var(--c-cyan)',
            },
            {
                key: 'restaking_wallets',
                name: 'Restakers',
                postfix: '',
                color: 'var(--c-blue)',
            },
            {
                key: 'operators',
                name: 'Operator Count',
                postfix: '',
                color: 'var(--c-orange)',
            },
        ]
    }

    return [
        {
            key: 'price',
            name: 'Price',
            prefix: '$',
            color: 'var(--c-magenta)',
        },
        {
            key: 'staked_tokens',
            name: `Staked ${symbol}`,
            postfix: '',
            color: 'var(--c-cyan)',
        },
        {
            key: 'staking_wallets',
            name: symbol === 'ETH' ? 'Validators' : 'Stakers',
            postfix: '',
            color: 'var(--c-yellow)',
        },
        {
            key: 'reward_rate',
            name: 'Reward Rate',
            postfix: '%',
            color: 'var(--c-blue)',
        },
        {
            key: 'inflation_rate',
            name: 'Inflation Rate',
            postfix: '%',
            color: 'var(--c-green)',
        },
        {
            key: 'fee_revenue',
            name: 'Annualized Fees',
            prefix: '$',
            color: 'var(--c-red)',
        },
    ]
}

export const getMetricTextForSharing = (
    asset = null,
    metricKey = '',
    metricGroup = null
) => {
    const metricValue = getMetricValueByKey(
        asset,
        metricKey,
        metricGroup?.timeframeKey
    )

    if (!isInvalidNumber(metricValue)) {
        return `${metricGroup?.label}: ${formatOutputNumber(metricValue, {
            precision: getMetricPrecision(metricKey, metricValue),
            forcePrecision: false,
            allowEmpty: false,
            withAbbreviation: metricKey !== 'price',
            showApproximation: true,
            prefix: metricGroup?.prefix ?? '',
            postfix: metricGroup?.postfix ?? '',
            showPlus: Boolean(metricGroup?.showPlus),
        })}\n\n`
    }

    return ''
}

export const getAssetMetricGroupsPerTab = (tab = '', slug = '') => {
    const metricsFromArchive = [
        ...MAIN_ASSET_METRIC_GROUPS,
        ...getMoreAssetMetricGroups(slug),
    ]

    if (tab === GENERAL_COLUMN) {
        return [
            ...metricsFromArchive.filter(metricGroup =>
                [
                    'price',
                    'marketcap',
                    'daily_trading_volume',
                    'inflation_rate',
                    'net_staking_flow',
                    'staked_tokens',
                    'capacity'
                ].includes(metricGroup?.key)
            ),
        ]
    }

    if (tab === RISK_COLUMN) {
        return [
            ...metricsFromArchive.filter(metricGroup =>
                [
                    'staking_marketcap',
                    'staking_ratio',
                    'total_staking_wallets',
                ].includes(metricGroup?.key)
            ),
            {
                key: 'fee_revenue',
                label: 'Annualized Fee Revenue',
                prefix: '$',
                tooltipTextObj: TOOLTIPS.feeRevenue,
                withPercentage: true,
                column: RISK_COLUMN,
                showPlus: false,
                withColor: true,
            },
            {
                key: 'circulating_percentage',
                label: 'Circulating Percentage',
                postfix: '%',
                tooltipTextObj: TOOLTIPS.circulatingPercentage,
                withPercentage: true,
                column: RISK_COLUMN,
                showPlus: false,
                withColor: true,
            },
            {
                key: 'staking_risk_rating',
                label: 'Staking Risk Rating',
                postfix: '',
                tooltipTextObj: TOOLTIPS.stakingRiskRating,
                withPercentage: false,
                column: RISK_COLUMN,
                showPlus: false,
                withColor: false,
            },
            {
                key: 'performance',
                label: (
                    <div className='flex items-center gap-1'>
                        Effectiveness{' '}
                        <span className='icon icon-candy !bg-contrast-2' />
                    </div>
                ),
                postfix: '%',
                tooltipTextObj: TOOLTIPS.raver,
                withPercentage: false,
                column: RISK_COLUMN,
                showPlus: false,
                withColor: false,
                customMetricKeys: ['performance'],
            },
        ]
    }

    if (tab === REWARD_COLUMN) {
        return [
            ...metricsFromArchive.filter(metricGroup =>
                [
                    'reward_rate',
                    'real_reward_rate',
                    'annualized_rewards_usd',
                ].includes(metricGroup?.key)
            ),
            {
                key: 'r_r_ratio',
                label: 'R/R Ratio',
                postfix: '%',
                tooltipTextObj: TOOLTIPS.rrRatio,
                withPercentage: true,
                column: REWARD_COLUMN,
                showPlus: false,
                withColor: true,
            },
            {
                key: 'reward_stability_365d',
                label: 'Reward Stability',
                postfix: '%',
                tooltipTextObj: TOOLTIPS.rewardStability,
                withPercentage: false,
                column: REWARD_COLUMN,
                showPlus: false,
                withColor: false,
            },
            {
                key: 'total_roi_365d',
                label: 'Total ROI 365d',
                postfix: '%',
                tooltipTextObj: TOOLTIPS.roi365dTotal,
                withPercentage: false,
                column: REWARD_COLUMN,
                showPlus: true,
                withColor: false,
                // NB: customMetricKeys are used instead of key for fetching and calculations
                customMetricKeys: ['price', 'staking_roi_365d'],
            },
            {
                key: 'staking_roi_365d',
                label: 'Staking ROI 365d',
                postfix: '%',
                tooltipTextObj: TOOLTIPS.roi365dStaking,
                withPercentage: false,
                column: REWARD_COLUMN,
                showPlus: true,
                withColor: false,
            },
            {
                key: 'price_roi_365d',
                label: 'Price ROI 365d',
                postfix: '%',
                tooltipTextObj: TOOLTIPS.roi365dPrice,
                withPercentage: false,
                column: REWARD_COLUMN,
                showPlus: true,
                withColor: false,
                customMetricKeys: ['price'],
            },
        ]
    }

    if (tab === MOMENTUM_COLUMN) {
        return [
            ...metricsFromArchive.filter(metricGroup =>
                [
                    'staked_tokens_trend_24h',
                    'trading_volume_trend_24h',
                ].includes(metricGroup?.key)
            ),
            {
                key: 'page_views_trend',
                label: 'Page Views Trend 12h',
                postfix: '%',
                tooltipTextObj: TOOLTIPS.pageViews12h,
                withPercentage: false,
                column: MOMENTUM_COLUMN,
                showPlus: true,
                withColor: true,
            },
        ]
    }

    return []
}

export const getSectionNameById = (
    id = '',
    symbol = '',
    isAVS = false,
    name = ''
) => {
    const sectionNames = {
        'section-key-staking-info': isAVS
            ? 'Key Restaking Info'
            : 'Key Staking Info',
        'section-performance-charts': 'Performance Charts',
        'section-analyze-staking-data': 'Analyze Staking Data',
        'section-calculate-rewards': 'Calculate Rewards',
        'section-run-a-validator': 'Run a Validator',
        'section-staking-faqs': isAVS ? 'Restaking FAQs' : 'Staking FAQs',
        'section-research-and-guides': 'Research & Guides',
        'section-exit-calculator': 'Exit Calculator',
    }

    if (id === `section-stake-${symbol}`) {
        return isAVS ? `${name} Operator` : `Stake ${symbol}`
    }

    if (id === `section-get-${symbol}`) {
        return `Get ${symbol}`
    }

    return sectionNames[id] || ''
}

export const getMetricGroupsForAVS = () => {
    return [
        {
            key: 'restaking_marketcap',
            label: 'Economic Security',
            prefix: '$',
            tooltipTextObj: TOOLTIPS.ethEconomicSecurity,
            withPercentage: true,
            column: RISK_COLUMN,
            showPlus: false,
            withColor: true,
        },
        {
            key: 'restaked_tokens',
            label: 'Restaked ETH',
            tooltipTextObj: TOOLTIPS.restakedEth,
            withPercentage: true,
            column: RISK_COLUMN,
            showPlus: false,
            withColor: true,
        },
        {
            key: 'restaking_wallets',
            label: 'Restakers',
            postfix: '',
            tooltipTextObj: TOOLTIPS.restakers,
            withPercentage: true,
            column: RISK_COLUMN,
            showPlus: false,
            withColor: true,
        },
        {
            key: 'operators',
            label: 'Operators',
            postfix: '',
            tooltipTextObj: TOOLTIPS.operators,
            withPercentage: true,
            column: RISK_COLUMN,
            showPlus: false,
            withColor: true,
        },
    ]
}

export const hasAssetTagKey = (asset, key) => {
    return Boolean(asset?.tags?.some(tag => tag.tagKey === key))
}

export const getAssetType = (asset = null) => {
    const isUnderMaintenance = asset?.underMaintenance === true
    const isSmartContract = hasAssetTagKey(asset, SMART_CONTRACT_KEY)
    const isPartial = asset?.slug === 'staking-rewards-partial-ethereum'
    const isLST = hasAssetTagKey(asset, LIQUID_STAKING_KEY)
    const isAVS = hasAssetTagKey(asset, AVS_KEY)
    const isStablecoin = hasAssetTagKey(asset, STABLECOIN_KEY)
    const isBTC = hasAssetTagKey(asset, BITCOIN_AND_OTHERS_KEY)
    const isUntracked = asset?.isActive === false

    return {
        isUnderMaintenance,
        isPartial,
        isSmartContract,
        isLST,
        isAVS,
        isStablecoin,
        isBTC,
        isUntracked,
    }
}
