import { useQuery } from '@tanstack/react-query'
import { useAnnouncementBar } from 'contexts/announcementBarContext'
import Link from 'next/link'
import { GTMEvent, logEvent } from 'utils/GTM'
import { EXTERNAL_ARROW } from 'utils/constants'

export const AnnouncementBar = ({ setIsShow }) => {
    const { announcementBarState } = useAnnouncementBar()

    const { data: announcementBar, isLoading } = useQuery({
        queryKey: [
            'announcementBar',
            announcementBarState.asset,
            announcementBarState.ecosystem,
            announcementBarState.tag,
        ],
        queryFn: async () => {
            try {
                const api = `${process.env.NEXT_PUBLIC_AD_MANAGER_URL}/api/a?asset=${announcementBarState.asset}&tag=${announcementBarState.ecosystem || announcementBarState.tag}`

                const response = await fetch(api, {
                    headers: {
                        'X-API-VERSION': 3,
                    },
                })

                return response.json()
            } catch (err) {
                console.log(err)
            }
        },
        enabled: Boolean(announcementBarState),
    })

    const isDestinationLinkBaseUrl =
        announcementBar?.destination_url?.includes('stakingrewards.com')

    return (
        !isLoading &&
        announcementBar?.content?.length > 0 && (
            <Link
                className='text-white flex items-center px-6 py-[11px] bg-customGradientTopBar relative top-0 h-fit w-full'
                href={announcementBar?.destination_url}
                onClick={() =>
                    logEvent(GTMEvent.TopBarAnnouncement, {
                        ad_action: 'click',
                        click_url: announcementBar?.destination_url,
                        ad_id: announcementBar?.id,
                        ad_name: announcementBar?.name,
                    })
                }
            >
                <div className='flex items-center flex-grow justify-center'>
                    <div className='rounded p-[6px] bg-white font-normal text-[10px] text-blue mr-[12px] hidden sm:inline whitespace-nowrap'>
                        {announcementBar?.button_text}
                    </div>
                    <p
                        className='announcement-bar'
                        dangerouslySetInnerHTML={{
                            __html: announcementBar?.content,
                        }}
                    ></p>
                    {isDestinationLinkBaseUrl ? (
                        <span
                            className='
                                opacity-0
                                icon
                                icon-arrow-right
                                min-w-[10px]
                                max-w-[10px]
                                !bg-white
                                sm:opacity-100'
                        />
                    ) : (
                        <span
                            className='opacity-0 min-w-[10px]
                                max-w-[10px]
                                sm:opacity-100'
                        >
                            {EXTERNAL_ARROW}
                        </span>
                    )}
                </div>
                <button
                    onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        setIsShow(false)
                    }}
                    className='icon icon-cross !min-w-[24px] !min-h-[24px] !bg-white self-center'
                ></button>
            </Link>
        )
    )
}
