import { FlagProvider } from '@unleash/proxy-client-react'
import { CustomAddressContextProvider } from 'contexts/customAddressContext'
import { GraphQLClient } from 'graphql-request'
import localFont from 'next/font/local'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { WalletState } from 'state/stakingPortfolio/walletState'
import { SWRConfig } from 'swr'

import { useProgressBar } from 'hooks/useProgressBar'
import { BrellaScript, SafaryScript } from 'utils/scripts'
import { ClarityTracking, GoogleTagManager, OptinMonsterTracker, RelicMonitoring } from 'utils/trackers'

import Intercom from '@intercom/messenger-js-sdk'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useGoogleTag } from 'components/ads/hooks'
import { Ui } from 'components/ui/ui'
import { REFRESH_INTERVAL_MS } from 'data/constants'
import 'styles/global.scss'
import { Noop } from 'utils/noop'

const client = new GraphQLClient(process.env.NEXT_PUBLIC_API_URL, {
    headers: {
        'X-API-Key': process.env.NEXT_PUBLIC_API_KEY,
    },
})

const isMaintenanceMode = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 2,
            refetchOnWindowFocus: false,
        },
    },
})


const interTight = localFont({
    src: [
        {
            path: '../../public/static/fonts/InterTight-ExtraBold.ttf',
            weight: '800',
            style: 'normal',
        },
        {
            path: '../../public/static/fonts/InterTight-Bold.ttf',
            weight: '700',
            style: 'normal',
        },
        {
            path: '../../public/static/fonts/InterTight-SemiBold.ttf',
            weight: '600',
            style: 'normal',
        },
        {
            path: '../../public/static/fonts/InterTight-Medium.ttf',
            weight: '500',
            style: 'normal',
        },
        {
            path: '../../public/static/fonts/InterTight-Regular.ttf',
            weight: '400',
            style: 'normal',
        },
        {
            path: '../../public/static/fonts/InterTight-Italic.ttf',
            weight: '400',
            style: 'italic',
        },
    ],
    variable: '--font-interTight',
})


export default function App({ Component, pageProps }) {
    // Temporary fix for signals bug, when trying to redirect from other pages to portfolio
    const a = WalletState.currentWallet.value
    useProgressBar()
    useGoogleTag()
    const router = useRouter()

    const ExtraProvider = Component.provider || Noop

    const isBrowser = typeof window !== 'undefined'

    const isInternalUser = isBrowser && Boolean(localStorage.getItem('isInternalUser') === 'true')

    const config = {
        url: 'https://us.app.unleash-hosted.com/usii0000/api/frontend/',
        clientKey: process.env.NEXT_PUBLIC_UNLEASH_API_KEY,
        appName: 'staking-rewards',
        context: {
            isInternalUser: isInternalUser ? true : false,
        },
    }

    useEffect(() => {
        // Check were client-side and initialize Intercom
        if (isBrowser && process.env.NEXT_PUBLIC_INTERCOM_ID) {
            Intercom({
                app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
            })
        }
    }, [isBrowser])

    // Render the component only if not in maintenance mode
    if (isMaintenanceMode || router.pathname === '/maintenance') {
        return <Component {...pageProps} />
    }

    return (
        <main
            className={`${interTight.className} ${interTight.variable}`}
        >
            {/* NB: Declare outside of SeoTags.
            Otherwise scripts get minified into _app chunk,
            making them not recognizable by the tracking tools. */}
            {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && <TrackingScripts />}
            {process.env.NEXT_PUBLIC_RELIC_MONITORING_ENABLED === 'true' && <RelicMonitoring />}
            <WidgetScripts />
            <FlagProvider config={config}>
                <SWRConfig
                    value={{
                        refreshInterval: REFRESH_INTERVAL_MS,
                        fetcher: ([query, variables]) => client.request(query, variables),
                    }}
                >
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools initialIsOpen={true} />
                        <ExtraProvider>
                            <Ui>
                                <CustomAddressContextProvider>
                                    <Component {...pageProps} />
                                </CustomAddressContextProvider>
                            </Ui>
                        </ExtraProvider>
                    </QueryClientProvider>

                    <div id='portal-root' />
                </SWRConfig>
            </FlagProvider>
        </main>
    )
}

export const TrackingScripts = () => {
    return (
        <>
            <ClarityTracking />
            <GoogleTagManager />
            <OptinMonsterTracker />
        </>
    )
}

export const WidgetScripts = () => {
    return (
        <>
            <BrellaScript />
            <SafaryScript />
        </>
    )
}
