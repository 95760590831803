import classNames from 'classnames'
import { AdBanner } from 'components/ads/adBanner'
import { ElementId, SlotId } from 'components/ads/utils'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { uiState } from 'state/ui'
import { TYPE_ASSET, TYPE_PROVIDER } from 'utils/constants'

import { useQuery } from '@tanstack/react-query'
import { Link } from 'components/ui/links/link'
import { useFloatingBanner } from 'contexts/floatingBannerContext'
import Image from 'next/image'
import { GTMEvent, logEvent } from 'utils/GTM'
import styles from './floatingBanner.module.scss'

export const FloatingBanner = () => {
    const router = useRouter()
    const [isVisible, setIsVisible] = useState(true)
    const { floatingBannerState } = useFloatingBanner()
    const isPageBottom = uiState.isPageBottom.value

    const {
        data: image,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['bannerImage', floatingBannerState],
        queryFn: async () => {
            const api = `${process.env.NEXT_PUBLIC_AD_MANAGER_URL}/api/b?asset=${floatingBannerState.asset}&tag=${floatingBannerState.ecosystem ? floatingBannerState.ecosystem : floatingBannerState.tag}`
            const response = await fetch(api, {
                headers: {
                    'X-API-VERSION': 1,
                },
            })

            const result = await response.json()
            const { name, destination_url, id } = result ?? {}
            const url = `${process.env.NEXT_PUBLIC_AD_MANAGER_URL}${result.location_url || ''}`
            const smallImageUrl = `${process.env.NEXT_PUBLIC_AD_MANAGER_URL}${result?.small_location_url}`

            return {
                imageUrl: url,
                smallImageUrl,
                id,
                name,
                destination: destination_url,
                alt: 'Ad Banner',
            }
        },
        enabled: Boolean(floatingBannerState),
    })

    if (
        router.asPath.includes('/terminal') ||
        router.asPath.includes('/summit') ||
        router.asPath.includes('/sreth')
    ) {
        return null
    }

    if (!image?.id) return null

    return (
        !isLoading &&
        !isError &&
        image?.id && (
            <div id='sticky-footer' className={styles.stickyFooter}>
                <div
                    className={classNames(styles.floatingBanner, {
                        [styles.hidden]: !isVisible,
                        [styles.bottom]: isPageBottom,
                    })}
                >
                    <Link
                        className='flex justify-center items-center'
                        href={image.destination}
                        onClick={() => {
                            logEvent(GTMEvent.AdLeaderboardBanner, {
                                ad_action: 'click',
                                ad_id: image.id,
                                ad_name: image.name,
                                click_url: image.destination,
                            })
                        }}
                    >
                        <Image
                            className={classNames(
                                'block max-w-[320px] justify-center items-center h-[50px] w-[400px] sm:hidden self-center'
                            )}
                            alt={image.alt}
                            src={image.smallImageUrl}
                            width={0}
                            height={0}
                            sizes='(max-width: 500px) 100vw'
                        />
                        <Image
                            className='hidden sm:block justify-center items-center self-center'
                            alt={image.alt}
                            src={image.imageUrl}
                            width={0}
                            height={0}
                            sizes='(max-width: 728px) 728px, (max-width: 768px) 728px, (max-width: 1024px) 970px, 1140px'
                            style={{
                                width: '100%',
                                maxWidth: '1140px',
                                height: 'auto',
                            }}
                        />
                        <div
                            className={styles.closeButtonWrapper}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIsVisible(false)
                            }}
                        >
                            <span
                                className={classNames(
                                    `icon icon-close`,
                                    styles.closeButton
                                )}
                            />
                        </div>
                    </Link>
                </div>
            </div>
        )
    )
}

// Old google ads floating banner, keeping for reference
export const GoogleFloatingBanner = () => {
    const router = useRouter()
    const { pathname, query } = router

    const [isVisible, setIsVisible] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)

    const isPageBottom = uiState.isPageBottom.value

    const slotId = SlotId.FloatingBanner

    const targeting = useMemo(
        () => ({
            component: 'null',
            slug: query?.slug,
            type:
                query?.slug && pathname?.includes('/asset/')
                    ? TYPE_ASSET
                    : TYPE_PROVIDER,
        }),
        [query?.slug, pathname]
    )

    const onLoaded = useMemo(
        () => () => {
            setIsLoaded(true)
        },
        [setIsLoaded]
    )
    if (router.asPath.includes('staking-assistant')) {
        return null
    }

    return (
        <div id='sticky-footer' className={styles.stickyFooter}>
            <div
                className={classNames(styles.floatingBanner, {
                    [styles.hidden]: !isLoaded || !isVisible,
                    [styles.bottom]: isPageBottom,
                })}
            >
                <AdBanner
                    slotId={slotId}
                    id={ElementId.FloatingBanner}
                    targeting={targeting}
                    onLoaded={onLoaded}
                />
                <div
                    className={styles.closeButtonWrapper}
                    onClick={() => {
                        setIsVisible(false)
                    }}
                >
                    <span
                        className={classNames(
                            `icon icon-close`,
                            styles.closeButton
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
