import Image from 'next/image'

import styles from './iconStack.module.scss'

export const IconStack = ({ icons = [], iconSize = 18, overlap = 5 }) => {
    return (
        <div className={styles.iconStack} style={{ marginLeft: `${overlap}px` }}>
            {icons?.filter(Boolean)?.map((iconUrl, idx) => {
                return (
                    <div
                        data-cy='icon-stack'
                        key={`icon-${iconUrl}-${idx}`}
                        className={styles.imgWrap}
                        style={{
                            marginLeft: `-${overlap}px`,
                            width: `${iconSize}px`,
                            height: `${iconSize}px`,
                        }}
                    >
                        <Image
                            src={iconUrl}
                            alt={'Icon'}
                            fill
                            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                        />
                    </div>
                )
            })}
        </div>
    )
}
