import { Search as SearchInput } from 'components/forms/inputs/search'
import { Link } from 'components/ui/links/link'
import { useSearchQuery } from 'data/queries'
import { useDebounce } from 'hooks/useDebounce'
import { useSearchParams } from 'hooks/useSearchParams'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { TYPE_ASSET, TYPE_PROVIDER, TYPE_VALIDATOR } from 'utils/constants'
import { StackedItems } from './stackedItems'

import styles from './search.module.scss'

export const Search = ({ searchTerms = '', type404 = null }) => {
    const { data, isSearching } = useSearchQuery(searchTerms, 10)
    const [assetResults, setAssetResults] = useState(null)
    const [providerResults, setProviderResults] = useState(null)
    const [validatorResults, setValidatorResults] = useState(null)

    const emptyAssets = (
        <span>
            {`No results for '${searchTerms}'. Represent a protocol and want to list? `}
            <Link
                href='https://stakingrewards.typeform.com/get-listed'
                className={styles.link}
            >
                Contact us.
            </Link>
        </span>
    )

    const emptyProviders = (
        <span>
            {`No results for '${searchTerms}'. Represent a validator and want to list? `}
            <Link
                href='https://stakingrewards.typeform.com/claim-profile'
                className={styles.link}
            >
                Contact us.
            </Link>
        </span>
    )

    useEffect(() => {
        const search = data?.search
        if (search?.length) {
            const assetsArray = search
                .filter(item => item.entityType === TYPE_ASSET)
                .map(item => JSON.parse(item.entityMeta))
            const providersArray = search
                .filter(item => item.entityType === TYPE_PROVIDER)
                .map(item => JSON.parse(item.entityMeta))
            const validatorResults = search
                .filter(item => item.entityType === TYPE_VALIDATOR)
                .map(item => JSON.parse(item.entityMeta))
            setAssetResults(assetsArray)
            setProviderResults(providersArray)
            setValidatorResults(validatorResults)
        }
    }, [data])

    const isLoading = isSearching || searchTerms === ''

    return (
        <div className={styles.searching}>
            <h3 className={styles.label}>Assets</h3>
            <div className={styles.results}>
                <StackedItems
                    data={assetResults}
                    isLoading={isLoading}
                    type={TYPE_ASSET}
                    emptyMessage={type404 === TYPE_ASSET ? emptyAssets : null}
                />
            </div>
            <h3 className={styles.label}>Providers</h3>
            <div className={styles.results}>
                <StackedItems
                    data={providerResults}
                    isLoading={isLoading}
                    type={TYPE_PROVIDER}
                    emptyMessage={
                        type404 === TYPE_PROVIDER ? emptyProviders : null
                    }
                />
            </div>
            <h3 className={styles.label}>Validators</h3>
            <div className={styles.results}>
                <StackedItems
                    data={validatorResults}
                    isLoading={isLoading}
                    type={TYPE_VALIDATOR}
                />
            </div>
        </div>
    )
}

export const WithSearch = ({ children, focusOnMount = false }) => {
    const [searchTerms, setSearchTerms] = useState('')
    const debouncedSearch = useDebounce(searchTerms, 300)
    const router = useRouter()
    const searchParams = useSearchParams()

    const is404 = router?.pathname?.includes('/404')
    const hasSearch =
        router?.asPath?.includes('?search') && router?.pathname === '/'
    const search = router?.query?.search

    useEffect(() => {
        if (hasSearch) {
            if (search && search !== searchTerms) {
                setSearchTerms(search)
            }
        }
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (is404 && (searchParams?.asset || searchParams?.provider)) {
            setSearchTerms(searchParams?.asset || searchParams?.provider)
        }
    }, [is404, searchParams])

    return (
        <div className={styles.withSearch}>
            <SearchInput
                searchTerms={searchTerms}
                setSearchTerms={setSearchTerms}
                doFocus={focusOnMount}
                large
            />
            {searchTerms !== '' ? (
                <Search
                    searchTerms={debouncedSearch}
                    type404={
                        is404
                            ? searchParams?.asset
                                ? TYPE_ASSET
                                : searchParams?.provider
                                  ? TYPE_PROVIDER
                                  : null
                            : null
                    }
                />
            ) : (
                children
            )}
        </div>
    )
}
