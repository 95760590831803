import { useRouter } from 'next/router'

export function useRouterPages() {
    const router = useRouter()
    const path = router.asPath

    const isStakeAppPage = path.startsWith('/stake-app')
    const isSwapAppPage = path.startsWith('/swap')

    const isPortfolioDashboard = path.startsWith('/terminal/dashboard')
    const isPortfolioLandingPage =
        path.startsWith('/terminal') && !isPortfolioDashboard

    const isStarknet = ['starknet', 'starknet-testnet'].includes(
        router.query?.input
    )
    const isEthChain = router?.query?.chain === 'ethereum'

    return {
        isStakeAppPage,
        isSwapAppPage,
        isPortfolioDashboard,
        isPortfolioLandingPage,
        isStarknet,
        isEthChain,
    }
}
