import classNames from 'classnames'
import { useLockBodyScroll } from 'hooks/useLockBodyScroll'
import localFont from 'next/font/local'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import styles from './modal.module.scss'


const interTight = localFont({
    src: [
        {
            path: './../../../public/static/fonts/InterTight-ExtraBold.ttf',
            weight: '800',
            style: 'normal',
        },
        {
            path: './../../../public/static/fonts/InterTight-Bold.ttf',
            weight: '700',
            style: 'normal',
        },
        {
            path: './../../../public/static/fonts/InterTight-SemiBold.ttf',
            weight: '600',
            style: 'normal',
        },
        {
            path: './../../../public/static/fonts/InterTight-Medium.ttf',
            weight: '500',
            style: 'normal',
        },
        {
            path: './../../../public/static/fonts/InterTight-Regular.ttf',
            weight: '400',
            style: 'normal',
        },
        {
            path: './../../../public/static/fonts/InterTight-Italic.ttf',
            weight: '400',
            style: 'italic',
        },
    ],
    variable: '--font-interTight',
})

export const Modal = ({
    className = '',
    overlayClassName = '',
    isOpen = false,
    onClose = () => {},
    isCloseable = true,
    children,
    header,
}) => {
    const overlayRef = useRef(null)
    const ref = useRef()
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        ref.current = document.querySelector('body')
        setIsMounted(true)
    }, [])

    useLockBodyScroll(isOpen)

    const closeModal = e => {
        e.preventDefault()
        e.stopPropagation()
        if (isCloseable) {
            onClose()
        }
    }

    const handleClickOutside = e => {
        if (e.target === overlayRef.current) {
            closeModal(e)
        }
    }

    return isMounted
        ? createPortal(
              <div
                  className={classNames(
                      interTight.variable,
                      interTight.className,
                      styles.modalOverlay,
                      {
                          [styles.show]: isOpen,
                      },
                      overlayClassName
                  )}
                  onClick={handleClickOutside}
                  ref={overlayRef}
              >
                  <div
                      className={classNames(styles.modal, className, {
                          [styles.hideClose]: !isCloseable,
                      })}
                  >
                      {isCloseable && (
                          <div className={styles.closeButtonWrap} onClick={closeModal}>
                              <span className={classNames(`icon icon-close !bg-contrast-6`, styles.closeButton)} />
                          </div>
                      )}
                      <div className={styles.header}>
                          <div className={styles.title}>{header}</div>
                      </div>
                      <div className={styles.content}>{children}</div>
                  </div>
              </div>,
              ref.current
          )
        : null
}
