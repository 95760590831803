import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'

export const BrellaScript = () => {
    const router = useRouter()

    useEffect(() => {
        // Function that reloads the script or reinitializes the widget
        const handleRouteChange = () => {
            // Remove existing script
            const existingScript = document.getElementById('brella-widget-script')
            existingScript?.parentNode?.removeChild(existingScript)

            // Recreate the script
            const script = document.createElement('script')
            script.src = 'https://widget.brella.io'
            script.async = true
            script.id = 'brella-widget-script'
            document.body.appendChild(script)
        }

        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    return <Script async id='brella-widget-script' strategy='lazyOnload' src='https://widget.brella.io' />
}

export const SafaryScript = () => {
    return (
        <Script
            id='safary-sdk'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
                __html: `
                        var script = document.createElement('script');
                        script.src = "https://tag.safary.club/stag-0.1.14.js";
                        script.async = true;
                        script.setAttribute('data-name', 'safary-sdk');
                        script.setAttribute('data-product-id', 'prd_Bw0agcJGfj');
                        script.integrity = "sha256-LNw5IVrCC2bO3s0X8xbY+ZfLe+FTwueEY4Lp56JuA2g=";
                        script.crossOrigin = "anonymous";
                        var target = document.head || document.body;
                        target.appendChild(script);
                    `,
            }}
        />
    )
}
